<template>

  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <b-card>
          <b-card-title>
            <b-button variant="primary" size="md" @click="printNoteTemplate" style="float:right">Εκτύπωση</b-button>
            <b-button variant="primary" size="xs" @click="$router.push({name: 'diet-plan-notes'})" class="mb-3">
              <i class="fa fa-long-arrow-alt-left"></i> Επιστροφή στα πρότυπα</b-button><br>
            Σημειώσεις διατροφικού πλάνου
          </b-card-title>
          <b-card-sub-title>Πληκτρολογήστε σημειώσεις προς εκτύπωση με τα διατροφικά σας πλάνα!</b-card-sub-title>
          <b-card-body>
            <div class="row">
              <div class="col-md-6">
                <b-form-group id="edit-name" label="Όνομα πρότυπου" label-for="edit-name">
                  <b-input-group>
                    <b-form-input id="edit-name" v-model="name" type="text" @change="notesChanged = true"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <quill-editor v-if="isLoaded" ref="noteEditor" v-model="content" :options="quillOptions" @change="notesChanged = true"/>
              </div>
            </div>


            <div class="text-right mt-2">

              <save-button type="submit" :text="isNew ? 'Δημιουργία νέου προτύπου' : $t('saveChanges')" @click="updateNoteTemplate"
              :disabled="!(notesChanged && name !== '' && content !== '')"></save-button>

            </div>
          </b-card-body>
        </b-card>

        <div v-show="isPrinting" id="print-area" ref="print-area">
          <print-header :title="name"/>

          <div class="note-body">
            <div v-html="content"></div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<style lang="scss">
.ql-editor{
  min-height: 200px;
}
</style>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {mapState} from "vuex";
import printHeader from '@components/print/header';
import PrintMixin from "@src/mixins/PrintMixin";


export default{
  components: { quillEditor, printHeader},
  page: {
    title: 'Επεξεργασία σημειώσεων',
    meta: [{ name: 'description' }],
  },
  mixins: [PrintMixin],
  data(){

    return {
      notesChanged: false,
      notesId:  this.$route.params.noteId,
      name: "",
      content: "",
      isLoaded: false,
      isPrinting: false
    }
  },
  computed:{
    ...mapState({
      notes: state => state.notes.notes,
    }),
    isNew(){
      return this.notesId === 'temp'
    },
    editor() {
      return this.$refs.noteEditor.quill
    }
  },

  created(){
    if(!this.isNew){
      let note = this.notes.find(x => x.id === parseInt(this.notesId));
      this.name = note.name;
      this.content = note.content.replaceAll('"', '');
    }
    this.$store.commit('ui/setActiveItem', {value: this.name, index: 0});
    this.isLoaded=true;
  },

  methods:{
    async updateNoteTemplate(){
      let template = {
        name: this.name,
        content: this.content
      }
      if(this.notesId === 'temp') return this.createNoteTemplate(template);

      template.id = this.notesId;

      this.$store.dispatch('notes/updateNote', template).then(res => {
        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το πρότυπο ενημερώθηκε επιτυχώς!'});
        this.notesChanged = false;
      }).catch( () => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});

      });

    },
    createNoteTemplate(template){
      this.$store.dispatch('notes/addNote', template).then(res => {
        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το πρότυπο δημιουργήθηκε επιτυχώς!'});
        this.$router.push({name: 'diet-plan-notes'})
      }).catch( () => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η δημιουργία απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});

      });

    },
    printNoteTemplate(){
      this.isPrinting = true;
      this.$nextTick(()=>{
        let divToPrint = this.$refs['print-area'].outerHTML;
        this.isPrinting=false;

        // remove empty paragraphs.
        divToPrint = divToPrint.replaceAll("<p><br></p>", "");
        divToPrint = divToPrint.replaceAll('display: none', "");

        this.print(divToPrint);
      });
    },
  }

}
</script>
